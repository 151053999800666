<template>
    <div class="dictionary-page">
        <div class="page-title">
            <div class="ctrl-btns">
                <a-button type="primary" size="small" @click="showAdd"><span class="iconfont icon-piliangtianjia"></span>新增维度</a-button>
                <a-button type="primary" size="small" @click="multiDel" :disabled="selectedRowKeys.length===0"><span class="iconfont icon-shanchu"></span>删除维度</a-button>
            </div>
            <span class="name">维度字典</span>
        </div>

        <div class="table-wrap">
            <a-table 
                :columns="columns" 
                :data-source="list" 
                :rowKey="(record)=>record.autoid" 
                :pagination="false" 
                :bordered="false"
                :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                >
                <template slot="actions" slot-scope="text,record">
                    <a-button type="link" @click="edit(record)">编辑</a-button>
                    <a-button type="link" @click="del(record.autoid)">删除</a-button>
                </template>
            </a-table>
        </div>

        <a-modal 
            :title="editKey?'编辑维度':'新增维度'"
            :visible="visbile"
            centered
            :footer="null"
            wrapClassName="edit-cube-win"
            @cancel="cancelAddDimension">
            <a-row type="flex" :gutter="[16,16]">
                <a-col flex="100px"><div class="label">维度名称</div></a-col>
                <a-col flex="auto">
                    <a-input v-model="dimensionName" style="width:100%;" />
                </a-col>
            </a-row>
            <a-row type="flex" :gutter="[16,16]">
                <a-col flex="100px"><div class="label">创建人</div></a-col>
                <a-col flex="auto">
                    <div class="text">管理员</div>
                </a-col>
            </a-row>
            <a-row type="flex" :gutter="[16,16]">
                <a-col flex="100px"><div class="label">维度介绍</div></a-col>
                <a-col flex="auto">
                    <a-textarea v-model="dimensionDesc" style="width:100%;height:100px" />
                </a-col>
            </a-row>
            <div class="btns">
                <a-button @click="cancelAddDimension">取消</a-button>
                <a-button type="primary" @click="addDimension">确定</a-button>
            </div>
        </a-modal>
    </div>
</template>

<script>
import { messageTips } from '../../until/tools';
const columns = [
    {
        title: '序号',
        dataIndex: 'autoid',
        key: 'autoid',
        scopedSlots: { customRender: 'autoid' },
    },
    {
        title: '维度名称',
        dataIndex: 'dimensionName',
        key: 'dimensionName',
    },
    {
        title: '维度介绍',
        dataIndex: 'dimensionDesc',
        key: 'dimensionDesc',
    },
    {
        title: '操作',
        dataIndex: 'actions',
        key: 'actions',
        scopedSlots: { customRender: 'actions' },
    },
];
export default {
    data() {
        return {
            columns,
            list:[],
            editKey:null,
            dimensionId:null,
            selectedRowKeys:[],
            visbile:false,
            dimensionName:'',
            dimensionDesc:''
        }
    },
    created() {
        this.getDimension();
    },
    methods:{
        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys;
        },
        cancelAddDimension() {
            this.visbile = false;
        },
        showAdd() {
            this.editKey = null;
            this.visbile = true;
        },
        getDimension() {
            this.$http.input_get_dimensions().then(res=>{
                if(res&&res.list) {
                    this.list = res.list;
                }
            })
        },
        addDimension() {
            if(this.dimensionName==='') {
                messageTips('请输入维度名称',2);
                return false;
            }
            let params = {
                dimensionName:this.dimensionName,
                lang:1
            }
            if(this.dimensionDesc) {
                params.dimensionDesc = this.dimensionDesc;
            }
            let funcName;
            if(this.editKey) {
                params.autoid = this.editKey;
                funcName = 'input_edit_dimension';
                params.dimensionId = this.dimensionId;
            } else {
                funcName = 'inputs_add_dimension';
            }
            this.$http[funcName](params).then(res=>{
                if(res) {
                    if(res.success) {
                        this.visbile = false;
                        messageTips(res.message,3);
                        this.getDimension();
                        this.dimensionName = '';
                        this.dimensionDesc = '';
                    } else {
                        messageTips(res.message,1);
                    }
                }
            });
        },
        edit(item) {
            this.dimensionName = item.dimensionName;
            this.dimensionDesc = item.dimensionDesc;
            this.dimensionId = item.dimensionId;
            this.editKey = item.autoid;
            this.visbile = true;
        },
        multiDel() {
            this.del(this.selectedRowKeys.join(','));
        },
        del(id) {
            var that = this;
            this.$confirm({
                title: '提示',
                content: '您确定要删除吗？',
                okText:'继续',
                cancelText:'取消',
                centered:true,
                onOk() {
                    let params = {
                        ids:id,
                    }
                    that.$http.inputs_del_dimension(params).then(res=>{
                        if(res){
                            if(res.success) {
                                that.getDimension();
                                messageTips(res.message,3);
                            } else {
                                messageTips(res.message,1);
                            }
                        }
                    });
                },
                onCancel() {},
            });
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../scss/inputs/dictionary.scss';
</style>
